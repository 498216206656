<template>
  <div>
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Categories</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Categories
            <span v-if="categories" class="tableTotal font-15 poppins"
              >(Total : {{ categories.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div class="float-right">
              <router-link to="/createCategory" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-2">Name</th>
                      <th class="col-md-3">Sequence</th>
                      <th class="col-md-3">Image</th>

                      <th class="col-md-2">Gender</th>

                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in categories" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div>{{ item.name }}</div>
                      </td>
                      <td>
                        <div>{{ item.sequence }}</div>
                      </td>

                      <td>
                        <div>{{ item.image }}</div>
                      </td>
                      <td>
                        <div>{{ item.gender }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <div class="mr-8">
                            <router-link
                              class="btn btn-primary"
                              :to="{
                                name: 'editCategory',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                          </div>
                          <!-- <div>
                                <deletemodal v-bind:mymodal="item"></deletemodal>
                              </div> -->
                          <div class="deletedModal mr-8">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteConfirm(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="!categories"
              >
                No Data Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="categories">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        prev-icon="<"
        next-icon=">"
        @input="getAllcategories()"
      />
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import homeService from '../../services/homeService';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      categories: [],
      page: '1',
      totalPages: 1,
      dialog: false,
    };
  },
  created() {
    this.getAllCategories();
  },
  methods: {
    async getAllCategories() {
      const data = {};
      data.page = this.page;
      const result = await homeService.getAllCategories(data);
      if (result.status == 200) {
        this.categories = result.data.data;
      }
      console.log('result', this.categories);
    },
    async deleteCategoryById(id) {
      const result = await homeService.deleteCategory(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log('result', this.categories);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteCategoryById(id);
        console.log('got confirmation');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
